@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif !important;
}
body {
  width: 100%;
  height: 100%;
  /* max-width: ; */
  max-height: 1024px;
  background: #ffffff;
}
:root {
  --light_dark: #5a6ded;
  --dark: #4834d4;
  --hue: #ffffff;
  --grayed: #e6e6e6;
  --dark_text: "#333333";
  --gray_text: "#666666";
  --danger: "#FF0018";
  --shade: #4834d41a;
}
input::selection {
  background-color: transparent;
  background: transparent;
}
input::-moz-selection {
  background: transparent;
}
::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
  background: rgba(133, 133, 133, 0.295);
}
::-webkit-scrollbar:hover {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-track {
  background: lightgray;
}
::-webkit-scrollbar-thumb {
  background: #4834d4;
  width: 0.5em;
  height: 0.5em;
  border-radius: 1rem;
}
.text-area::-webkit-scrollbar {
  display: none;
}
.newscroll::-webkit-scrollbar {
  display: block;
}
.rs-row {
  height: 73px !important;
}
